import React, {Fragment} from 'react'

const SelfDescription = () => {
    return (
        <Fragment>
            <h1 style={{marginLeft:"-5px"}}><strong>Weiyan Zhu</strong></h1>
            <p style={{lineHeight:"0px"}}>Computer Science</p>
            <p style={{marginTop:"-10px"}}>Master's student at University of California, Berkeley</p>
        </Fragment>
    )
}

export default SelfDescription
